<template>
	<article class="view-home register bg-wave-gradient">
		<div class="site-wrapper flex-row flex-center-h flex-center-v">
			<transition name="slide-fade">
				<div v-show="registrationComplete"
						 class="flex-row flex-center-h">
					<section class="card shadow registration-complete">
						<header class="flex-col flex-center-h rythm-v">
							<img role="img"
									 aria-hidden="true"
									 src="@/assets/svg/infovac-icon.svg"
									 width="100px" />
							<h2>Votre compte est enregistré, merci !</h2>
						</header>
						<p><strong>Vous devez confirmer votre adresse e-mail</strong> pour finaliser votre inscription.</p>
						<p>
							Consultez votre boîte de réception, un lien d'activation vous a été envoyé.
						</p>
					</section>
				</div>
			</transition>

			<div v-show="!registrationComplete"
					 class="login-register flex-row flex-center-h">
				<section class="section--login flex-row flex-center-h flex-center-v">
					<header class="rythm-v">
						<img role="img"
								 aria-hidden="true"
								 src="@/assets/svg/infovac-icon.svg"
								 width="100px" />

						<h2>Créez votre compte</h2>

						<div>
							<p>L’inscription est ouverte à tous les professionnels de santé</p>
							<p class="registration-info">

								<svg class="icon icon-info"
										 role="img"
										 xmlns="http://www.w3.org/2000/svg"
										 width="22"
										 height="22"
										 fill="none"
										 viewBox="0 0 22 22"><path fill="currentColor" fill-rule="evenodd" d="M11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2ZM0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11Z" clip-rule="evenodd"/><path fill="currentColor" fill-rule="evenodd" d="M11 10C11.5523 10 12 10.4477 12 11V15C12 15.5523 11.5523 16 11 16C10.4477 16 10 15.5523 10 15V11C10 10.4477 10.4477 10 11 10Z" clip-rule="evenodd"/><path fill="currentColor" d="M12 7C12 7.55228 11.5523 8 11 8C10.4477 8 10 7.55228 10 7C10 6.44772 10.4477 6 11 6C11.5523 6 12 6.44772 12 7Z"/></svg>
								<em>Vous ne pouvez pas utiliser le compte du site www.infovac.fr sur cette nouvelle plateforme. <br>Si vous n’avez pas encore de compte, inscrivez-vous pour discuter avec nos experts.</em>
							</p>
						</div>
					</header>

					<footer>
						<h3>Vous déjà êtes inscrit&nbsp;?</h3>
						<router-link to="/login"
												 class="button--outline"><svg class="icon"
									 role="img"
									 xmlns="http://www.w3.org/2000/svg"
									 width="18"
									 height="18"
									 fill="none"
									 viewBox="0 0 18 18"><path fill="currentColor" d="M10.8.9a.9.9 0 0 1 .9-.9h3.6A2.7 2.7 0 0 1 18 2.7v12.6a2.7 2.7 0 0 1-2.7 2.7h-3.6a.9.9 0 1 1 0-1.8h3.6a.9.9 0 0 0 .9-.9V2.7a.9.9 0 0 0-.9-.9h-3.6a.9.9 0 0 1-.9-.9z" /><path fill="currentColor" d="M6.564 3.864a.9.9 0 0 1 1.272 0l4.5 4.499.006.007a.897.897 0 0 1 .258.627v.006a.898.898 0 0 1-.258.627l-.006.007-4.5 4.5a.9.9 0 1 1-1.272-1.273L9.527 9.9H.9a.9.9 0 1 1 0-1.8h8.627L6.564 5.136a.9.9 0 0 1 0-1.272z" /></svg><span class="caption">Connexion</span></router-link>
					</footer>
				</section>

				<section class="section--register flex-row flex-center-h flex-center-v">
					<form action="/register"
								@submit.prevent="registerUser">
						<div class="input-group">
							<label for="email">Adresse e-mail</label>
							<p class="hint">Préférez une adresse personnelle ; les restrictions de sécurité des adresses institutionnelles peuvent bloquer les e-mails de la plateforme.</p>
							<input type="email"
										 name="email"
										 id="email"
										 v-model="registerData.email"
										 required />
						</div>

						<div class="input-group">
							<label for="password">Mot de passe <span class="small">(12 caractères minimum)</span></label>
							<input type="password"
										 name="password"
										 id="password"
										 v-model="registerData.password"
										 :invalid="passwordIsInvalid"
										 required />
						</div>

						<div class="input-group">
							<label for="password-check">Mot de passe <span class="small">(vérification)</span></label>
							<input type="password"
										 name="password-check"
										 id="password-check"
										 v-model="registerData.passwordCheck"
										 :invalid="passwordIsInvalid"
										 required />
						</div>

						<footer>
							<div class="input-group--inline input-group-radio">
								<input type="checkbox"
											 name="rgpd-consent"
											 id="rgpd-consent"
											 v-model="registerData.privacyConsent"
											 required />
								<label for="rgpd-consent">J'accepte les
									<a href="https://www.infovac.fr/mentions-legales"
										 target="_blank"
										 rel="noreferrer">Conditions Générales d'Utilisation</a>
								</label>
							</div>

							<ul role="list"
									:class="formIsComplete ? 'form-validations is-valid' : 'form-validations'">
								<li v-for="(validate, index) in validations"
										:key="index">
									<svg role="img"
											 :class="validate.checked ? 'icon icon-checkmark checked' : 'icon icon-checkmark'"
											 xmlns="http://www.w3.org/2000/svg"
											 width="22"
											 height="22"
											 fill="none"
											 viewBox="0 0 22 22">
																																																																								<svg:style>
																																																																									.icon-checkmark .icon-checked {
																																																																										opacity: 0;
																																																																										fill: #3aaa35;
																																																																									}
																																																																									.icon-checkmark .icon-unchecked {
																																																																										fill: #95c11f;
																																																																										opacity: 1;
																																																																									}

																																																																									.icon-checkmark.checked .icon-checked {
																																																																										opacity: 1;
																																																																									}
																																																																									.icon-checkmark.checked .icon-unchecked {
																																																																										opacity: 0;
																																																																									}
																																																																								</svg:style>
																																																																								<path
																																																																									class="icon-unchecked"
																																																																									fill="#95c11f"
																																																																									fill-rule="evenodd"
																																																																									d="M11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2ZM0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11Z"
																																																																									clip-rule="evenodd"
																																																																								/>
																																																																								<g class="icon-checked">
																																																																									<path
																																																																										fill="#3AAA35"
																																																																										fill-rule="evenodd"
																																																																										d="M14.663 2.77915C12.8902 1.98926 10.9096 1.79357 9.01657 2.22128C7.12351 2.649 5.41942 3.67719 4.15845 5.15252C2.89749 6.62785 2.14721 8.47126 2.01951 10.4078C1.89181 12.3444 2.39354 14.2704 3.44987 15.8985C4.50619 17.5266 6.06051 18.7697 7.88102 19.4423C9.70153 20.1149 11.6907 20.1809 13.5518 19.6307C15.413 19.0804 17.0464 17.9432 18.2084 16.3888C19.3705 14.8344 19.9989 12.9459 20 11.0052V10.0857C20 9.53344 20.4477 9.08572 21 9.08572C21.5523 9.08572 22 9.53344 22 10.0857V11.0057C21.9986 13.3778 21.2306 15.6864 19.8103 17.5863C18.39 19.4862 16.3936 20.876 14.1189 21.5486C11.8442 22.2211 9.41299 22.1404 7.18792 21.3183C4.96285 20.4963 3.06312 18.977 1.77206 16.9871C0.480993 14.9971 -0.13223 12.6432 0.0238445 10.2762C0.179919 7.90932 1.09693 5.65626 2.63811 3.85308C4.17929 2.0499 6.26206 0.793217 8.57581 0.270457C10.8896 -0.252303 13.3103 -0.0131332 15.477 0.952297C15.9815 1.17708 16.2082 1.76825 15.9834 2.27272C15.7587 2.7772 15.1675 3.00393 14.663 2.77915Z"
																																																																										clip-rule="evenodd"
																																																																									/>
																																																																									<path
																																																																										fill="#3AAA35"
																																																																										fill-rule="evenodd"
																																																																										d="M21.7068 2.29816C22.0975 2.68848 22.0978 3.32165 21.7075 3.71237L11.7075 13.7224C11.52 13.9101 11.2656 14.0155 11.0002 14.0156C10.7349 14.0157 10.4805 13.9103 10.2929 13.7227L7.29289 10.7227C6.90237 10.3322 6.90237 9.69903 7.29289 9.30851C7.68342 8.91798 8.31658 8.91798 8.70711 9.30851L10.9996 11.601L20.2925 2.29886C20.6829 1.90814 21.316 1.90783 21.7068 2.29816Z"
																																																																										clip-rule="evenodd"
																																																																									/>
																																																																								</g>
																																																																							</svg>

									<span class="caption">{{ validate.caption }}</span>
								</li>
							</ul>

							<div class="input-group--controls">
								<button class="button--primary"
												type="submit"
												:disabled="!formIsComplete || isLoading">
									<svg class="icon"
											 role="img"
											 xmlns="http://www.w3.org/2000/svg"
											 width="21"
											 height="17"
											 fill="none"
											 viewBox="0 0 21 17">
																																																																																																																		                    <path
																																																																																																																		                      fill="#fff"
																																																																																																																		                      fill-rule="evenodd"
																																																																																																																		                      d="M1.245 11.445A4.25 4.25 0 0 1 4.25 10.2h5.95a4.25 4.25 0 0 1 4.25 4.25v1.7a.85.85 0 0 1-1.7 0v-1.7a2.55 2.55 0 0 0-2.55-2.55H4.25a2.55 2.55 0 0 0-2.55 2.55v1.7a.85.85 0 0 1-1.7 0v-1.7a4.25 4.25 0 0 1 1.245-3.005zM7.225 1.7a2.55 2.55 0 1 0 0 5.1 2.55 2.55 0 0 0 0-5.1zm-4.25 2.55a4.25 4.25 0 1 1 8.5 0 4.25 4.25 0 0 1-8.5 0zM17 4.25c.47 0 .85.38.85.85v5.1a.85.85 0 0 1-1.7 0V5.1c0-.47.38-.85.85-.85z"
																																																																																																																		                      clip-rule="evenodd"
																																																																																																																		                    />
																																																																																																																		                    <path fill="#fff" fill-rule="evenodd" d="M13.6 7.65c0-.47.38-.85.85-.85h5.1a.85.85 0 0 1 0 1.7h-5.1a.85.85 0 0 1-.85-.85z" clip-rule="evenodd" />
																																																																																																																		                  </svg>
									<span class="caption">Créer le compte</span>
								</button>
							</div>
						</footer>
					</form>
				</section>
			</div>
		</div>
	</article>
</template>

<script>
import { HTTP } from "../http-common";
import { emailIsValid } from "../libs/helpers";
import { EventBus } from "../event-bus.js";

export default {
	name: "home",

	data: function () {
		return {
			isLoading: false,
			registrationComplete: false,
			registerData: {
				email: "",
				password: "",
				passwordCheck: "",
				privacyConsent: false,
			},
		};
	},

	computed: {
		// Current user data
		isFromFrance: function () {
			return this.$store.state.isFromFrance;
		},

		validations: function () {
			return [
				{
					type: "email",
					caption: "L'adresse e-mail est valide.",
					checked: this.validateEmail(this.registerData.email),
				},
				{
					type: "passwordLength",
					caption: "La longueur du mot de passe est de 12 caractères minimum.",
					checked: this.registerData.password.length >= 12,
				},
				{
					type: "passwordMatch",
					caption: "Les mots de passe correspondent.",
					checked: this.registerData.password != "" && this.registerData.passwordCheck != "" && this.registerData.password === this.registerData.passwordCheck,
				},
				{
					type: "privacyConsent",
					caption: "La politique de confidentialité est acceptée.",
					checked: this.registerData.privacyConsent,
				},
			];
		},

		formIsComplete: function () {
			let trues = 0;
			this.validations.forEach((el) => {
				if (el.checked) trues++;
			});
			return trues === this.validations.length;
		},

		passwordIsInvalid: function () {
			// No data
			if (this.registerData.password === "" && this.registerData.passwordCheck === "") return false;

			let passwordLength = this.validations.find((el) => el.type === "passwordLength");
			let passwordMatch = this.validations.find((el) => el.type === "passwordMatch");

			return !passwordLength.checked || !passwordMatch.checked;
		},
	},

	async mounted() {
		// Update IP check
		// this.isFrenchIP();
	},

	methods: {
		// Is the user from France?
		isFrenchIP() {
			this.$store.dispatch("FROM_FRANCE");
		},

		// Create new user
		// POST registerData
		registerUser: function () {
			this.$toasted.clear();

			// prevent multiple submissions
			if (this.isLoading) {
				// already loading (eg. form sent twice with keyboard)
				return;
			}

			this.isLoading = true;

			this.$store
				.dispatch("CREATE_USER", this.registerData)
				.then(() => {
					this.isLoading = false;

					// EventBus.$emit("trackEvent", "Inscription", "Compte validé, utilisateur ajouté");
					this.registrationComplete = true;

					// this.$toasted.global.appSuccess({
					// 	message: "<strong>Votre compte est enregistré, merci !</strong>"
					// });
					// this.$toasted.global.appInfo({
					// 	message:
					// 		"<span>Vous avez reçu un e-mail d'activation, <strong>confirmez votre adresse e-mail pour continuer.</strong></span>"
					// });
				})
				.catch((response) => {
					this.isLoading = false;

					if (response.status === 409) {
						// TODO: propose password reset
						// EventBus.$emit("trackEvent", "Inscription", "Erreur : e-mail existant");
						// this.$toasted.global.appError({
						// 	message: 'Cette adresse e-mail est déjà utilisée, connectez-vous pour accéder à l’application.',
						// });

						this.$toasted.show('<div class="message-wrapper"><strong>Cette adresse e-mail est déjà utilisée.</strong><br>Vous pouvez vous connecter ou changer de mot de passe.</div>', {
							containerClass: "toasted toasted-column",
							type: "error",
							theme: "primary",
							position: "center",
							duration: null,
							closeOnSwipe: false,
							action: [

								{
									text: "Connexion",
									onClick: (e, toastObject) => {
										toastObject.goAway(0);
										this.$router.push('/');
									},
								},
								{
									text: "Nouveau mot de passe",
									onClick: (e, toastObject) => {
										toastObject.goAway(0);
										this.$router.push('/password-reset');
									},
								},
								{
									text: "Annuler",
									onClick: (e, toastObject) => {
										toastObject.goAway(0);
										return;
									},
								},
							],
						});

					}

					if (response.status === 422 && response.data.payload.error.type === "required-field") {
						// EventBus.$emit("trackEvent", "Inscription", "Erreur : champs requis manquant");
						this.$toasted.global.appError({
							message: "Un champs requis est manquant",
						});
					}

					if (response.status === 422 && response.data.payload.error.type === "invalid-email") {
						// EventBus.$emit("trackEvent", "Inscription", "Erreur : e-mail invalide");
						this.$toasted.global.appError({
							message: "L'adresse e-mail est invalide",
						});
					}
				});
		},

		validateEmail: function (email) {
			return emailIsValid(email);
		},
	},
};
</script>


<style lang="scss" scoped>
@import "src/scss/login-register.scss";

.site-wrapper {
	padding-bottom: 0;
	padding-top: 0;
}

.registration-info {
	margin-bottom: 1rem;
	margin-top: 2rem;
	font-size: .875em;
	text-align: left;
	padding: 0 1.5em;

	.icon {
		display: inline-block;
		height: auto;
		margin-bottom: -2px;
		margin-right: .25em;
		vertical-align: baseline;
		width: 1em;
		color: #229ad2;
	}
}

@media (max-width: 768px) {
	.registration-info br {
		display: none;
	}
}
</style>
